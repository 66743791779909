import React from "react";

const Navbar = () => {
  return (
    <div className="nav-bar">
      <span className="nav-text home" id="home">
        <a href="/" className="color-flow">
          DEREK 311
        </a>
      </span>
      <span className="nav-text home" id="home"></span>
      <span className="nav-text">
        <a className="contact color-flow" href="mailto:hi@derek311.com">
          CONTACT ME
        </a>
      </span>
    </div>
  );
};

export default Navbar;
