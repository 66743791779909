import './notfound.css'
import './App.css'
const NotFound = () => {
    return(
    <div className="content-404">
        <div className="message">
        <a className='text text-color' href="/" >Coming Soon</a>
        <p className='lost-message'
        ><span className='text-color'>Lost in digital wilderness!</span>
        <br/>This website is being updated.</p>
        </div>
    </div>
    )
}

export default NotFound;