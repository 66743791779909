import './notfound.css'
import './App.css'
const NotFound = () => {
    return(
    <div className="content-404">
        <div className="message">
        <a className='text text-color' href="/" >404 Not Found</a>
        <p className='lost-message'
        ><span className='text-color'>Lost in digital wilderness!</span>
        <br/>This page is nowhere to be found.</p>
        </div>
    </div>
    )
}

export default NotFound;