import React from 'react'

const Content = () => {
  return (
    <div className="content">
        <span className='text text-color'>Coming Soon</span>
    </div>
  )
}

export default Content