import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Content from './Content';
import ComingSoon from './ComingSoon'
import Navbar from './Navbar';
import NotFound from './NotFound';

function App() {
  return (
    // <div className="main">
    //   <Navbar/>
    //   <Content/>
    // </div>
    <Router>
      <div className='App'>
        <Navbar/>
        <Routes>
          <Route path="/" element={<ComingSoon/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
